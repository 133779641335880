
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import * as StatServices from '@/services/StatServices'


@Component
export default class BounceRateChart extends Vue {
    @Prop({ required: true }) from!: Date;
    @Prop({ required: true }) to!: Date;

    chartOptions = {
        chart: {
            id: 'vistors'
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
        },
        labels: [],
        dataLabels: {
            enabled: false
        },
    }

    series = [] as any[]

    loading = false;

    @Watch('from', { immediate: true })
    @Watch('to', { immediate: true })
    async onDateChange() {
        this.loading = true;
        const data = await StatServices.GetVisitorSources(this.from, this.to);
        this.series = data.map((e: any) => e.referrals)
        this.chartOptions = {
            ...this.chartOptions,
            labels: data.map((e: any) => e.source)
        }
        this.loading = false;
    }
}
